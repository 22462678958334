'use client'

import React, { useEffect, useMemo } from 'react'

import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { useRouter } from 'next/navigation'

type SisterSite = { name: string; url: string }
type SisterSiteConfig = SisterSite | Record<'isda' | 'createiq', SisterSite>

function isProductKeyedConfig(config: SisterSiteConfig): config is Record<'isda' | 'createiq', SisterSite> {
  return 'isda' in config || 'createiq' in config
}

export default function SisterSiteButton() {
  const theme = useTheme()
  const router = useRouter()

  const sisterSite = useMemo<SisterSite | null>(() => {
    try {
      const sisterSiteStr = process.env.NEXT_PUBLIC_SISTER_FRONTEND
      if (sisterSiteStr) {
        const sisterSiteConfig = JSON.parse(sisterSiteStr) as SisterSiteConfig
        return isProductKeyedConfig(sisterSiteConfig) ? sisterSiteConfig[theme.product] : sisterSiteConfig
      }
    } catch {
      // Ignore, probably .env things
    }

    return null
  }, [theme.product])

  useEffect(() => {
    if (!sisterSite) {
      router.push('/login/main')
    }
  }, [router, sisterSite])

  return (
    sisterSite && (
      <Button
        component="a"
        href={`${sisterSite.url}/login/main`}
        variant="contained"
        color="primary"
        data-qa="sister-site-link"
      >
        {sisterSite.name}
      </Button>
    )
  )
}
