'use client'

import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export default styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(3.75),
  marginBottom: theme.spacing(5.25),
  justifyContent: 'center',
  alignItems: 'center',
}))
